<template>
  <v-container fluid>
    <v-row>
      <v-col col="12" md="12" lg="12" outlined>
        <v-card class="pa-4 rounded-xl">
          <v-card-title class="justify-left d-flex flex-row">
            <p>Posiciones</p>
            <!-- <v-spacer></v-spacer>
            <v-btn class="ma-3 rounded-lg text-capitalize" dense color="primary" :to="{ name: 'noticias-add' }">
              Agregar Polla Callejera
            </v-btn> -->
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" lg="6">
                  <v-select v-model="polla_id" :items="pollasSelect" item-value="id" item-text="name" label=""
                    outlined dense @change="getPositionsBySelected(polla_id)" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" lg="12">
                  <table-posiciones :polla_id="polla_id"></table-posiciones>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TablePosiciones from "@/components/tables/TablePosiciones.vue";
export default {
  name: "polla-callejera-posiciones",
  components: { TablePosiciones },
  watch: {
    pollasSelect: function (value) {
       this.polla_id = value[0].id;
    }
  },
  data: () => ({
    polla_id: null,
  }),
  computed: {
    ...mapState({
      pollasSelect: state => state.polla.pollasSelect,
    }),
  },
  methods: {
        ...mapActions(["getPollas"]),
        getPositionsBySelected(polla_id) {
        },
        getFetch(){
          this.getPollas({ params: { "polla_type_id": 1}});
        }
  },
   created() {
        this.getFetch();
    },
};
</script>

<style>
</style>